var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([(_vm.devMode)?{key:"subtitle",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'widget_docs',
          params: {
            category: 'regions',
            widget: 'employee_comments',
            id: _vm.$router.currentRoute.params.id,
          },
          query: _vm.$router.currentRoute.query,
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-code-greater-than")]),_vm._v(" docs ")],1)]},proxy:true}:null,{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table,"noun":"Report"}})]},proxy:true}],null,true)},[_vm._v(" Employee Comments ")]),_c('v-card',[_c('v-data-table',_vm._b({attrs:{"hide-default-footer":""},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.context",fn:function({item}){return [_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.survey.name))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.question.body))])]}},{key:"item.text_entry",fn:function({value, item}){return [_c('v-alert',{staticClass:"my-1",attrs:{"icon":"mdi-message-outline","color":"blue-grey","text":""}},[_c('div',{staticClass:"commentQuestion text-caption mt-n3 mb-1"},[_vm._v(" "+_vm._s(item.position_category.name)+": ")]),_vm._v(" "+_vm._s(value)+" ")])]}}])},'v-data-table',_vm.tableProps,false)),(!_vm.noData)?_c('v-pagination',_vm._b({staticClass:"mt-2 text-center",on:{"input":_vm.changePage}},'v-pagination',_vm.paginationProps,false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }