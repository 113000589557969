<template>
  <div>
    <cp-h1>
      Locations
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'widget_docs',
            params: {
              category: 'regions',
              widget: 'locations',
              id: $router.currentRoute.params.id,
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <v-card>
      <v-data-table
        v-bind="tableProps"
        @click:row="rowClick"
        @update:options="updateTableOptions"
        class="clickable-rows"
        hide-default-footer
      >
        <template #item.ingage_stats.current_score="{value, item}">
          <span
            v-if="value"
            :set="(diff = parseDiff(value, item.ingage_stats.previous_score))"
          >
            <a :id="`open-engagement-modal-${item.m_id}`" @click.stop.prevent>
              {{ diff.score }}
              <span v-if="diff.delta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/employee_engagement_trends`"
              :activator="`#open-engagement-modal-${item.m_id}`"
              width="800"
            />
          </span>
        </template>

        <template #item.intouch_stats.agreement_importance="{value, item}">
          <span
            v-if="value"
            :set="
              (diff = parseDiff(
                value,
                get(
                  item,
                  'intouch_stats.previous_year_results.agreement_importance'
                )
              ))
            "
          >
            <a :id="`open-satisfaction-modal-${item.m_id}`" @click.stop.prevent>
              {{ diff.score }}
              <span v-if="diff.delta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/resident_satisfaction_trends`"
              :activator="`#open-satisfaction-modal-${item.m_id}`"
              width="800"
            />
          </span>
        </template>

        <template
          #item.introduce_stats.progress.complete_percentage="{value, item}"
        >
          <span v-if="value">
            {{ roundPrct(value) }}
          </span>
          <!-- <span v-if="value">
            <a href="#" :id="`open-progress-modal-${item.m_id}`" @click.stop>
              {{ roundPrct(value) }}
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/plan-progress`"
              :activator="`#open-progress-modal-${item.m_id}`"
              width="800"
            />
          </span> -->
        </template>

        <template
          #item.introduce_stats.compliance.compliant_percentage="{value, item}"
        >
          <span v-if="value">
            {{ roundPrct(value) }}
          </span>
          <!-- <span
            v-if="value"
            :set="
              (diff = parseDiff(
                value,
                item.introduce_stats.compliance.previous_score
              ))
            "
          >
            <a href="#" :id="`open-compliance-modal-${item.m_id}`" @click.stop>
              {{ diff.score }}%
              <span v-if="diff.showDelta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/plan-compliance`"
              :activator="`#open-compliance-modal-${item.m_id}`"
              width="800"
            />
          </span> -->
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";
import { roundPrct } from "@/lib/formats";

export default {
  mixins: [widgetMixin, tableMixin],
  computed: {
    ...mapGetters("auth", { has: "clientHasService" }),
    headers() {
      const headers = [{ text: "Location", value: "name", sortable: true }];

      if (this.has("ingage"))
        headers.push({
          text: "Engagement",
          value: "ingage_stats.current_score",
          align: "center",
        });

      if (this.has("intouch"))
        headers.push({
          text: "Satisfaction",
          value: "intouch_stats.agreement_importance",
          align: "center",
          sortable: true,
        });

      if (this.has("introduce"))
        headers.push(
          {
            text: "Plans Progress",
            value: "introduce_stats.progress.complete_percentage",
            align: "center",
            sortable: true,
          },
          {
            text: "Plans Compliance",
            value: "introduce_stats.compliance.compliant_percentage",
            align: "center",
            sortable: true,
          }
        );

      return headers;
    },
  },
  methods: {
    get,
    roundPrct,
    parseDiff(currentValue, previousValue) {
      const score = Math.round(parseFloat(currentValue));
      const delta = previousValue
        ? score - Math.round(parseFloat(previousValue))
        : false;
      return { score, delta, showScore: !!score, showDelta: !!delta };
    },
    rowClick(item) {
      this.$router.push({ name: "property", params: { id: item.m_id } });
    },
  },
};
</script>
