<template>
  <v-alert v-if="error" type="error">{{ error }}</v-alert>
  <div v-else class="widget">
    <cp-loading :loading="!instance" class="widget">
      <component
        v-if="foundWidget"
        :is="instance ? instance.id : 'span'"
        :data-widget="instance && instance.id"
        v-bind="{ instance }"
        ref="component"
      />
      <v-alert v-else-if="instance" type="warning">
        Widget Missing: {{ instance.id }}
      </v-alert>
    </cp-loading>
  </div>
</template>

<script>
import { deepMerge } from "@cp/utils/objectUtils";

import { loadWidget, unregisterWidget } from "@/store/modules/widgets";
import widgets from "@/widgets";

export default {
  name: "WidgetV2",
  components: widgets,
  props: {
    url: { type: String, required: true },
    params: { type: Object, default: () => ({}) },
    widgetId: { default: undefined },
  },
  data() {
    return {
      error: "",
      instance: null,
    };
  },
  computed: {
    loading() {
      return !this.instance;
    },
    foundWidget() {
      return this.instance && widgets.hasOwnProperty(this.instance.id);
    },
  },
  async mounted() {
    const url = this.url;
    const urlQuery = this.$router.currentRoute.query;
    const query = deepMerge({}, urlQuery, this.params);
    const widgetId = this.widgetId;
    this.instance = await loadWidget({ url, query, widgetId });
    this.$emit("initialized", this.instance);
  },
  beforeDestroy() {
    console.log("WidgetV2.beforeDestroy");
    unregisterWidget(this.instance);
  },
};
</script>
