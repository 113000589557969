import Api from "./Api";
import Widgets from "./Widgets";
import Reports from "./Reports";

export default [
  {
    path: "/api/:id?",
    name: "api_docs",
    component: Api,
    meta: {
      title: "Api Docs",
    },
  },

  {
    path: "/widgets/:category?/:id?/:widget?",
    name: "widget_docs",
    component: Widgets,
    meta: {
      title: "Widget Docs",
    },
  },

  {
    path: "/docs/reports/:widget?",
    name: "report_docs",
    component: Reports,
    meta: {
      title: "Reports Docs",
    },
  },
];
