<template>
  <pretty-stat-card v-if="card" v-bind="$attrs" />
  <div v-else-if="cards">
    <pretty-stat-card
      v-for="(stat, i) in $attrs.stats"
      :key="i"
      v-bind="{ stats: stat, ...cardsProps }"
    />
  </div>
  <v-row v-else-if="row" v-bind="$attrs"></v-row>
</template>

<script>
export default {
  props: {
    card: { type: Boolean, default: false },
    cards: { type: Boolean, default: false },
    row: { type: Boolean, default: false },
    colProps: { type: Object, default: () => ({}) },
  },

  computed: {
    cardsProps() {
      const { stats, ...props } = this.$attrs;
      return props;
    },
  },
};
</script>
