import { get, mapObject } from "@cp/utils/objectUtils";
import { parse } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";
import { flattenPath } from "@cp/utils/pathUtils";
import { toYQ, mapStrToMyLabel, mapStrToYLabel } from "@/lib/quarterOfYear";
import { coaxRoundPrct } from "@/lib/formats";

const metricFormatMap = {
  agreement_importance: {
    format: x => (x ? Math.round(x) : "-"),
    align: "start",
  },
  response_rate: {
    format: coaxRoundPrct,
    align: "end",
  },
  likely_to_renew_percentage: {
    format: coaxRoundPrct,
    align: "end",
  },
  yes_no: {
    format: coaxRoundPrct,
    align: "end",
  },
};

const excludeMetricsByScope = {
  indicators: ["response_rate"],
  questions: ["response_rate"],
  topics: ["response_rate"],
  factors: ["response_rate"],
};

const compoundDatesRegex = /^\d{4}-\d{2}-\d{2}:\d{4}-\d{2}-\d{2}$/;
function cmpdDates(str) {
  if (str === "historical:historical")
    return `Before ${parse()
      .subtract(3, "years")
      .format("MMM YYYY")}`;
  if (compoundDatesRegex.test(str)) {
    const [startDate, endDate] = str.split(":").map(parse);
    const sameYear = startDate.year() === endDate.year();
    return sameYear
      ? `${startDate.format("MMM")}-${endDate.format(
          "MMM"
        )} ${startDate.year()}`
      : `${startDate.format("MMM YYYY")} - ${endDate.format("MMM YYYY")}`;
  }
}

export default {
  data() {
    return {
      selected: [],
      dateIntervalLabelFns: {
        month: mapStrToMyLabel,
        quarter: str => cmpdDates(str) || toYQ(str),
        year: str => cmpdDates(str) || mapStrToYLabel(str),
      },
      metricLabelFormatMap: mapObject(metricFormatMap, x => x.format),
    };
  },
  computed: {
    //reportType
    reportType() {
      return get(
        this.$store.state,
        flattenPath(this.instance.p.s.params, "structures.report_type"),
        "default"
      );
    },
    reportTypes() {
      return get(
        this.$store.state,
        flattenPath(this.instance.modulePath, "reportTypes")
      );
    },
    selectedReportType() {
      return findByObj(this.reportTypes, { value: this.reportType });
    },
    selectedReportTypeIndex: {
      get() {
        const found = this.reportTypes.findIndex(
          x => x.value === this.reportType
        );
        return found !== -1 ? found : 0;
      },
      set(index = 0) {
        const selectedReportType = this.reportTypes[index];
        const reportType = selectedReportType.value;
        const reportTypeOptions = get(selectedReportType, "options", {});
        const defaultDateInterval = get(
          this.meta,
          "additional_meta.defaults.interval"
        );
        const state = get(this.$store.state, this.instance.modulePath);
        if (reportTypeOptions.metric) {
          state.metric = get(this.meta, "additional_meta.defaults.metric");
        } else {
          state.metric = "";
        }
        this.$store.dispatch(this.instance.p.a.updateParams, {
          structures: {
            report_type: reportType,
            date_interval: reportTypeOptions.date_interval
              ? defaultDateInterval
              : "",
          },
        });
        this.$CpEvent.$emit("updateRoute", {
          query: {
            reportType,
            metric: state.metric,
            dateInterval: defaultDateInterval,
          },
        });
        this.$CpEvent.$emit("timelinesMixin:reportTypeChanged");
      },
    },

    // metric
    metric() {
      return get(
        this.$store.state,
        flattenPath(this.instance.modulePath, "metric"),
        "agreement_importance"
      );
    },
    metrics() {
      return get(this.selectedReportType, "options.metric", []).map(x => {
        if ((excludeMetricsByScope[this.scope] || []).includes(x.value)) {
          x.props = { disabled: true };
        }
        return x;
      });
    },
    selectedMetric() {
      return findByObj(this.metrics, { value: this.metric });
    },
    selectedMetricIndex: {
      get() {
        const found = this.metrics.findIndex(x => x.value === this.metric);
        return found !== -1 ? found : 0;
      },
      set(index) {
        if (!this.metrics[index]) return;
        const metric = this.metrics[index].value;
        const state = get(this.$store.state, this.instance.modulePath);
        state.metric = metric;
        this.$CpEvent.$emit("updateRoute", { query: { metric } });
        this.$CpEvent.$emit("timelinesMixin:metricChanged");
      },
    },

    // dateInterval
    dateInterval() {
      return get(
        this.$store.state,
        flattenPath(this.instance.p.s.params, "structures.date_interval"),
        "quarter"
      );
    },
    dateIntervals() {
      if (!get(this.selectedReportType, "options.date_interval")) return [];
      return get(
        this.$store.state,
        flattenPath(this.instance.modulePath, "dateIntervals.values"),
        []
      );
    },
    selectedDateInterval() {
      return findByObj(this.dateIntervals, { value: this.dateInterval });
    },
    selectedDateIntervalIndex: {
      get() {
        const found = this.dateIntervals.findIndex(
          x => x.value === this.dateInterval
        );
        return found !== -1 ? found : 0;
      },
      set(index) {
        const dateInterval = this.dateIntervals[index].value;
        this.$store.dispatch(this.instance.p.a.updateParams, {
          structures: { date_interval: dateInterval },
        });
        this.$CpEvent.$emit("updateRoute", { query: { dateInterval } });
        this.$CpEvent.$emit("timelinesMixin:metricChanged");
      },
    },

    timelineHeaders() {
      const firstTimeline = get(this.data, [0, "timeline"], {});
      const qH = Object.keys(firstTimeline).sort();
      const textFn = this.dateIntervalLabelFns[this.dateInterval];
      return [
        {
          value: "metric",
          text: get(this.selectedMetric, "label"),
          ...get(metricFormatMap, this.metric, {}),
        },
        ...qH.map((q, i) => ({
          value: `t_${i + 1}`, // v-for in {integer} is 1-indexed?? wtf? ok.
          text: textFn(q),
          path: [q, this.metric],
          sortable: false,
          ...get(metricFormatMap, this.metric, {}),
        })),
      ];
    },

    showTimelines() {
      if (this.reportType !== "timeline") return false;
      return this.selected.length || this.showIndexesInTimeline;
    },

    showSelect() {
      return this.reportType === "timeline";
    },
  },
  watch: {
    scope() {
      this.checkMetricScope();
    },
  },
  methods: {
    checkMetricScope() {
      if (!this.metrics.includes(this.metric)) this.selectedMetricIndex = 0;
    },
    resetSelected() {
      this.selected.splice(0, this.selected.length);
    },
  },
  mounted() {
    this.checkMetricScope();
  },
};
