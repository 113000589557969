var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"rightOfTitle",fn:function(){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","small":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-down")]),_vm._v(" View By: "+_vm._s(_vm.selectedScope.label)+" ")],1)]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedScopeIndex),callback:function ($$v) {_vm.selectedScopeIndex=$$v},expression:"selectedScopeIndex"}},_vm._l((_vm.scopes),function(s){return _c('v-list-item',{key:s.value},[_vm._v(" "+_vm._s(s.label)+" ")])}),1)],1)],1)]},proxy:true},{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table}})]},proxy:true}])},[_vm._v(" Portfolio ")]),_c('list-stats-cards',_vm._b({},'list-stats-cards',{ statCards: _vm.statCards, table: _vm.table },false)),_c('v-card',[_c('v-data-table',_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"headers":_vm.headers,"hide-default-footer":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.ingage_stats.current_score",fn:function({value, item}){return [(value)?_c('span',{attrs:{"set":(_vm.diff = _vm.parseDiff(value, item.ingage_stats.previous_score))}},[_c('a',{attrs:{"id":`open-engagement-modal-${item.m_id}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.diff.score)+" "),(_vm.diff.delta)?_c('span',[(_vm.diff.delta > 0)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-trending-up")]):_vm._e(),(_vm.diff.delta < 0)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trending-down")]):_vm._e(),_c('span',{class:{
                  'success--text': _vm.diff.delta > 0,
                  'error--text': _vm.diff.delta < 0,
                }},[_vm._v(" "+_vm._s(Math.abs(_vm.diff.delta))+" ")])],1):_vm._e()]),(value)?_c('widget-modal',{attrs:{"url":`properties/${item.m_id}/employee_engagement_trends`,"activator":`#open-engagement-modal-${item.m_id}`,"width":"800"}}):_vm._e()],1):_vm._e()]}},{key:"item.intouch_stats.agreement_importance",fn:function({value, item}){return [(value)?_c('span',{attrs:{"set":(_vm.diff = _vm.parseDiff(
              value,
              _vm.get(
                item,
                'intouch_stats.previous_year_results.agreement_importance'
              )
            ))}},[_c('a',{attrs:{"id":`open-satisfaction-modal-${item.m_id}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.diff.score)+" "),(_vm.diff.delta)?_c('span',[(_vm.diff.delta > 0)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-trending-up")]):_vm._e(),(_vm.diff.delta < 0)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trending-down")]):_vm._e(),_c('span',{class:{
                  'success--text': _vm.diff.delta > 0,
                  'error--text': _vm.diff.delta < 0,
                }},[_vm._v(" "+_vm._s(Math.abs(_vm.diff.delta))+" ")])],1):_vm._e()]),(value)?_c('widget-modal',{attrs:{"url":`properties/${item.m_id}/resident_satisfaction_trends`,"activator":`#open-satisfaction-modal-${item.m_id}`,"width":"800"}}):_vm._e()],1):_vm._e()]}},{key:"item.introduce_stats.progress.complete_percentage",fn:function({value, item}){return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.roundPrct(value))+" ")]):_vm._e()]}},{key:"item.introduce_stats.compliance.compliant_percentage",fn:function({value, item}){return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.roundPrct(value))+" ")]):_vm._e()]}}]),model:{value:(_vm.tableSelected),callback:function ($$v) {_vm.tableSelected=$$v},expression:"tableSelected"}},'v-data-table',_vm.tableProps,false),_vm.tableListeners)),(!_vm.noData && !_vm.scopeChangeLoading)?_c('v-pagination',_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }