<template>
  <div>
    <cp-h1
      css-class="align-center"
      right-class="justify-space-between align-center flex-grow-1 ml-8"
    >
      Residents

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <list-stats-cards v-bind="{ statCards, table }" />

    <v-card>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="
          item => $router.push({ name: 'resident', params: { id: item.id } })
        "
        hide-default-footer
      >
        <template #item.alert="{ item }">
          <alerts-explain :alerts="item.recent_alerts" />
        </template>

        <template #item.residents.first_name="{ item }">
          {{ item.first_name }} {{ item.last_name }}
        </template>

        <template #item.survey_stats="{ item }">
          <span v-if="item.surveys && item.surveys.unconfidential_sent">
            {{ item.surveys.unconfidential_sent }}
            /
            {{ item.surveys.unconfidential_started || 0 }}
            /
            {{ item.surveys.unconfidential_competed || 0 }}
          </span>
          <span v-else>
            None
          </span>
        </template>

        <template #item.unit_number="{ item }">
          {{
            (item.units || [{ unit_number: "None" }])
              .map(x => x.unit_number)
              .join(",")
          }}
        </template>

        <template #item.renewal="{item}">
          <likelihood-emoticon
            v-if="item.intouch_stats && item.intouch_stats.length"
            :likelihood="item.intouch_stats.at(-1).likelihood"
          />
          <span v-else>N/A</span>
        </template>

        <template #item.last_response_date="{value}">
          {{ value && toMDY(value) }}
        </template>
      </v-data-table>

      <v-pagination
        class="mt-2 text-center pagination"
        v-bind="paginationProps"
        v-on="paginationListeners"
        :total-visible="15"
      />
    </v-card>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { residentsTable } from "@/store/modules/residents.js";
import { toMDY } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/collectionUtils";

import { roundPrct } from "@/lib/formats";

const statCards = [
  {
    title: "Summary",
    icon: "home-account",
    basePath: ["summary_object", "general"],
    stats: [
      [
        "Residents",
        "resident_count",
        { icon: "home-account", iconProps: { color: "primary" } },
      ],
      ["Properties", "location_count"],
      [
        "Surveys Sent",
        "sent",
        {
          basePath: ["summary_object", "general"],
        },
      ],
      [
        "Response Rate",
        "response_rate",
        {
          format: roundPrct,
          click: { filter: { survey_options: "Surveys Taken" } },
          basePath: ["summary_object", "general"],
        },
      ],
      // // [
      // //   "All Alerts",
      // //   "all_alerts",
      // //   { click: { filter: { survey_options: "Has Alerts" } } },
      // // ],
      // // [
      // //   "Unread Alerts",
      // //   "unread_alerts",
      // //   { click: { filter: { survey_options: "Has Unread Alerts" } } },
      // // ],
    ],
  },
  {
    title: "Surveys",
    icon: "emoticon",
    basePath: ["summary_object", "satisfaction"],
    stats: [
      [
        "Overall",
        "overall",
        { icon: "emoticon", iconProps: { color: "success" } },
      ],
      ["Satisfaction", "satisfaction"],
      ["Move In", "move_in"],
      ["Service Request", "maintenance"],
      ["Follow Up", "followup"],
    ],
  },
  {
    title: "Service Issues (90 days)",
    icon: "account-voice",
    basePath: ["summary_object", "reponse"],
    stats: [
      [
        "Total",
        "total_recent_maintenance_issues",
        {
          icon: "account-voice",
          iconProps: { color: "orange" },
          basePath: ["summary_object", "service_issues"],
        },
      ],
      [
        "Resident Satisfaction",
        "resident-satisfaction",
        {
          click: { filter: { survey_options: "Has Recent Maintenance Issues", resident_surveys: 24 } },
          basePath: ["summary_object", "service_issues"],
        },
      ],
      [
        "Service Request Follow Up",
        "service-request-follow-up",
        {
        click: { filter: { survey_options: "Has Recent Maintenance Issues", resident_surveys: 26 } },
          basePath: ["summary_object", "service_issues"],
        },
      ],
      [
        "Move In Follow Up",
        "move-in-follow-up",
        {
          click: { filter: { survey_options: "Has Recent Maintenance Issues", resident_surveys: 25 } },
          basePath: ["summary_object", "service_issues"],
        },
      ],
      // ["Missed", "missed"],
    ],
  },
  {
    title: "Renewal",
    icon: "head-sync",
    basePath: ["summary_object", "renewal"],
    stats: [
      [
        "Likely",
        "likey_percentage",
        {
          format: roundPrct,
          icon: "autorenew",
          iconProps: { color: "success" },
        },
      ],
      ["Likely", "likey"],
      ["Unsure", "unsure"],
      [
        "Unlikely",
        "unlikely",
        { click: { filter: { survey_options: "Unlikely to Renew" } } },
      ],
    ],
  },
];

export default {
  name: "Residents",
  mixins: [residentsTable.mixin, residentsTable.filtersMixin],
  data() {
    return { statCards };
  },
  computed: {
    so() {
      return get(this.meta, "summary_object", false);
    },
  },
  methods: {
    toMDY,
    pluralize,
    emoticonProps(v) {
      const value = parseFloat(v);

      if (value >= 4)
        return {
          icon: "mdi-emoticon-excited",
          color: "success",
          tooltip: "Likely to Renew",
        };
      if (value <= 2)
        return {
          icon: "mdi-emoticon-sad",
          color: "error",
          tooltip: "Unlikely to Renew",
        };

      return {
        icon: "mdi-emoticon-neutral",
        color: null,
        tooltip: "Neutral to Renew",
      };
    },
  },
};
</script>
