import { get } from "@cp/utils/objectUtils";
import { flattenPath } from "@cp/utils/pathUtils";

export default {
  data() {
    return {
      scopeChangeLoading: false,
    };
  },
  computed: {
    scope() {
      const path = flattenPath(this.instance.p.s.params, "structures.scope");
      return get(this.$store.state, path);
    },
    scopes() {
      return get(
        this.$store.state,
        flattenPath(this.instance.modulePath, "scopes")
      );
    },
    selectedScopeIndex: {
      get() {
        const found = this.scopes.findIndex(x => x.value === this.scope);
        return found !== -1 ? found : 0;
      },
      set(index) {
        this.scopeChangeLoading = true;
        const scope = this.scopes[index];
        const tableOptions = get(
          this.$store.state,
          this.instance.p.s.tableOptions
        );
        tableOptions.page.number = 1;
        this.$store
          .dispatch(this.instance.p.a.updateParams, {
            structures: { scope: scope.value },
          })
          .finally(() => {
            this.scopeChangeLoading = false;
          });

        this.$CpEvent.$emit("updateRoute", { query: { scope: scope.value } });
        this.$CpEvent.$emit("multiScopeMixin:scopeChanged");
      },
    },
    selectedScope() {
      return this.scopes[this.selectedScopeIndex];
    },
  },
};
