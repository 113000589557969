<template>
  <cp-loading :loading="loading" render-before-loaded>
    <v-row>
      <v-col cols="3">
        <cp-h1 v-bind="{ backButtonRoute }">{{ parent.name }}</cp-h1>
        <v-card>
          <v-card-text>
            <table class="summary-table">
              <tbody>
                <tr>
                  <td>Locations</td>
                  <td colspan="2">{{ parent.properties.length }}</td>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td colspan="2">{{ parent.user_count }}</td>
                </tr>
                <tr v-if="parent.managers && parent.managers.length">
                  <td>{{ pluralize("Manager", parent.managers.length) }}</td>
                  <td colspan="2" class="list">
                    <template v-if="Array.isArray(parent.managers)">
                      <div
                        v-for="{ label, value } in parent.managers"
                        :key="value"
                      >
                        {{ label }}
                      </div>
                    </template>
                    <span v-else-if="parent.managers">
                      {{ parent.managers }}
                    </span>
                    <template v-else>
                      None
                    </template>
                  </td>
                </tr>
                <tr
                  v-if="
                    parent.maintenance_managers &&
                      parent.maintenance_managers.length
                  "
                >
                  <td>
                    Maintenance
                    {{
                      pluralize("Manager", parent.maintenance_managers.length)
                    }}
                  </td>
                  <td colspan="2">
                    <template v-if="parent.maintenance_managers.length">
                      <div
                        v-for="{ label, value } in parent.maintenance_managers"
                        :key="value"
                      >
                        {{ label }}
                      </div>
                    </template>
                    <template v-else>
                      None
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>

        <v-card class="mt-3 ShowNav">
          <v-list dense>
            <v-list-item
              v-for="{ iconProps, icon, title, widget } in reportMenu"
              :key="widget"
              :to="{ name: 'region', params: { widget } }"
            >
              <v-list-item-icon>
                <v-icon v-bind="iconProps">{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-subtitle">
                {{ title || snakeToTitleCase(widget) }}
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9">
        <template v-if="reportMenu.length">
          <widget-v2
            v-if="showWidget"
            :url="baseUrl + `/${$route.params.id}/${subViewKey}`"
            @initialized="setParent"
          />
          <component v-else :is="subViewKey" @initialized="setParent" />
        </template>
      </v-col>
    </v-row>
  </cp-loading>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import pluralize from "pluralize";

import { mapItem } from "@cp/utils/jsonapiUtils";
import { get } from "@cp/utils/objectUtils";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

import properties from "./PropertyList.vue";

const baseUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/regions`;
const reportMenuOrder = [
  "locations",
  "resident_satisfaction",
  "resident_reasons_leaving_report",
  "resident_comments",
  "employee_priorities",
  "employee_comments",
  "employee_development",
  "employee_compliance",
];

const reportWidgets = {
  locations: {
    service: "intel",
    icon: "mdi-home-city-outline",
  },
  resident_satisfaction: {
    service: "intouch",
    icon: "mdi-emoticon-happy-outline",
  },
  resident_reasons_leaving_report: {
    service: "intouch",
    title: "Why Residents Are Leaving",
    icon: "mdi-target",
  },
  resident_comments: {
    service: "intouch",
    icon: "mdi-account-voice",
  },
  employee_priorities: {
    service: "ingage",
    title: "Strengths and Opportunities",
    icon: "mdi-bullseye-arrow",
    iconProps: { color: "success" },
  },
  employee_comments: {
    service: "ingage",
    icon: "mdi-account-voice",
  },
  employee_development: {
    service: "introduce",
    title: "Onboarding and Offboarding",
    icon: "mdi-bullseye",
    iconProps: { color: "error" },
  },
  employee_compliance: {
    service: "introduce",
    title: "Task Compliance",
    icon: "mdi-checkbox-multiple-marked-outline",
  },
};

export default {
  components: { properties },
  data() {
    return {
      baseUrl,
      loading: true,
      parent: {
        name: "",
        city: "",
        state: "",
        properties: [],
        managers: [],
        maintenance_managers: [],
      },
    };
  },
  computed: {
    ...mapState("haystackUser", ["identityLoading"]),
    ...mapGetters("haystackUser", ["clientServices", "thisAppAccessLevel"]),
    backButtonRoute() {
      if (["regional_manager"].includes(this.thisAppAccessLevel))
        return { name: "portfolio" };
      if (["client-admin"].includes(this.thisAppAccessLevel))
        return { name: "portfolio" };
      return {};
    },
    reportMenu() {
      return reportMenuOrder
        .map(id => ({ widget: id, ...(reportWidgets[id] || {}) }))
        .filter(x => !x.service || this.clientServices.includes(x.service));
    },
    subViewKey() {
      return this.$route.params.widget || this.reportMenu[0].widget;
    },
    showWidget() {
      return reportWidgets.hasOwnProperty(this.subViewKey);
    },
  },
  methods: {
    snakeToTitleCase,
    pluralize,
    setParent(instance) {
      this.loading = false;
      this.parent = mapItem(
        get(this.$store.state, instance.p.s.meta + ".parent")
      );
    },
  },
  mounted() {
    if (
      !this.$route.params.widget &&
      this.reportMenu &&
      this.reportMenu.length
    ) {
      const { name, params } = this.$route;
      const widget = this.reportMenu[0].widget;

      this.$router.replace({ name, params: { ...params, widget } });
    }
  },
};
</script>

<style lang="scss" scoped>
.CpH1::v-deep h1 {
  line-height: 0.95em;
}

.ShowNav {
  .v-list-item {
    padding-left: 0;
    padding-right: 0;
    &:hover {
      &,
      & * {
        text-decoration: none;
      }
    }
    &.v-list-item--active:before {
      background: $primary;
    }

    &.v-list-item--active .v-icon {
      color: $primary;
    }
  }

  .v-list-item__content {
    font-weight: 500;
    font-size: 0.85rem;
  }

  .v-list-item__icon {
    align-self: center;
    margin: 0 5px;
  }

  .v-list-item__action {
    align-self: stretch;
    margin: 0 5px;
  }
}
</style>
