<template>
  <div>
    <cp-h1>
      Resident Comments
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'widget_docs',
            params: {
              category: 'regions',
              widget: 'resident_comments',
              id: $router.currentRoute.params.id,
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div cols="3" class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td class="pr-3 text-caption">
                  Date Range:
                </td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn v-on="on" color="primary" small text>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedDateRange.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedDateRangeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="dr in dateRanges" :key="dr.value">
                          <v-list-item-content>{{
                            dr.label
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #item.context="{item}">
          <div class="text-subtitle-2">{{ item.survey.name }}</div>
          <div class="text-caption">{{ item.question.body }}</div>
        </template>

        <template #item.text_entry="{value, item}">
          <v-alert
            icon="mdi-message-outline"
            color="blue-grey"
            class="my-1"
            text
          >
            <div class="commentQuestion text-caption mt-n3 mb-1">
              {{ item.position_category.name }}:
            </div>
            {{ value }}
          </v-alert>
        </template>

        <template #item.question_response_summary="{item}">
          <question-response-summary v-bind="{ item, table }" />
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { findByObj } from "@cp/utils/arrayUtils";

const scopeHeaders = {
  surveys: [
    { text: "Survey", value: "name" },
    { text: "Comments", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      align: "center",
    },
  ],
  questions: [
    { text: "Question", value: "name" },
    { text: "Type", value: "question_type_slug" },
    { text: "Comments", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      align: "center",
    },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return { scopeHeaders };
  },
  computed: {
    title() {
      return `${this.parent.name} - Resident Comments`;
    },
    commentsByMonth() {
      const months = this.data.reduce((r, comment) => {
        const label = parse(comment.created_at).format("MMMM YYYY");
        const foundMonth = findByObj(r, { label });
        if (foundMonth) {
          foundMonth.comments.push({
            c_id: comment.m_id,
            s_name: comment.survey_name,
            q_body: comment.body,
            comment: comment.text_value_1,
            date: toMDY(comment.created_at),
          });
        } else {
          r.push({
            label,
            ym: parse(comment.created_at).format("YYYY-MM"),
            id: `${label}-comments`,
            comments: [
              {
                c_id: comment.m_id,
                s_name: comment.survey_name,
                q_body: comment.body,
                comment: comment.text_value_1,
                date: toMDY(comment.created_at),
              },
            ],
          });
        }
        return r;
      }, []);
      return months.sort((a, b) => {
        if (a.ym > b.ym) return -1;
        if (a.ym < b.ym) return 1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

::v-deep .v-alert--text {
  padding-top: 4px;
  padding-bottom: 0;
}

.commentQuestion {
  color: $gray-4;
  margin-bottom: 8px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
