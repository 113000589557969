<template>
  <td :colspan="colspan" class="px-0">
    <div class="d-flex align-stretch">
      <v-menu>
        <template #activator="{on}">
          <v-btn v-on="on" color="primary" class="subSettingsBtn" text>
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-table-cog</v-icon>
              <span class="currentScope">
                View by: {{ properize(selectedSubScope) }}
              </span>
            </div>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="navigateToSubReport">
            <v-list-item-content>
              View this {{ pluralize(properize(parentScope), 1) }}'s
              {{ properize(selectedSubScope) }} report
            </v-list-item-content>
            <v-list-item-icon color="primary" class="ml-3 mr-0">
              <v-icon>mdi-arrow-top-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <template v-if="subScopes.length > 1">
            <v-subheader>View By:</v-subheader>
            <v-list-item-group :value="selectedSubScopeIndex" color="primary">
              <v-list-item
                v-for="iScope in subScopes"
                :key="iScope"
                @click="changeSubScope(iScope)"
              >
                {{ properize(iScope) }}
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-list>
      </v-menu>

      <v-data-table
        v-bind="tableProps"
        :headers="headers"
        class="SubTable"
        @update:options="updateTableOptions"
        :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25] }"
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
          ><slot :name="name" v-bind="slotData"></slot
        ></template>
        <template #header.name>
          {{ capitalize(selectedSubScope) }}
        </template>

        <template v-for="(component, key) in defaultCells" v-slot:[key]="data">
          <component
            :is="component"
            :key="`row-${data.index}-${key}`"
            v-bind="data"
          />
        </template>
      </v-data-table>
    </div>
  </td>
</template>

<script>
import { flattenPath } from "@cp/utils/pathUtils";
import { get, pick } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";
import defaultCells from "@/components/tables/cells/defaultCells";

export default {
  mixins: [widgetMixin, tableMixin],
  props: {
    itemId: { type: String, required: true },
    parentShowSelect: { type: Boolean, default: false },
    scopeHeaders: { type: Object, required: true },
  },
  computed: {
    colspan() {
      const adjust = this.parentShowSelect ? 2 : 1;
      return this.parentHeaders.length + adjust;
    },
    parentScope() {
      return get(
        this.$store.state,
        this.instance.parent.p.s.params + ".structures.scope"
      );
    },
    subScopes() {
      return get(
        this.$store.state,
        flattenPath(
          this.instance.parent.modulePath,
          "subScopes",
          this.parentScope
        )
      );
    },
    parentSelectedScope() {
      const path = flattenPath(
        this.instance.parent.p.s.params,
        "structures.scope"
      );
      return get(this.$store.state, path);
    },
    selectedSubScope() {
      return get(
        this.$store.state,
        flattenPath(this.instance.p.s.params, "structures.scope")
      );
    },
    selectedSubScopeIndex() {
      return this.subScopes.indexOf(this.selectedSubScope);
    },
    parentHeaders() {
      return get(
        this.scopeHeaders,
        this.parentSelectedScope,
        this.scopeHeaders.default
      );
    },
    headers() {
      return get(
        this.scopeHeaders,
        this.selectedSubScope,
        this.scopeHeaders.default
      );
    },
    overriddenSlotKeys() {
      return [...Object.keys(this.$slots), ...Object.keys(this.$scopedSlots)];
    },
    defaultCells() {
      const cells = Object.keys(defaultCells).filter(
        key => !this.overriddenSlotKeys.includes(key)
      );
      return pick(defaultCells, cells);
    },
  },
  methods: {
    changeSubScope(scope) {
      const subTableOptions = get(
        this.$store.state,
        this.instance.p.s.tableOptions
      );
      subTableOptions.page.number = 1;
      this.$store.dispatch(this.instance.p.a.updateParams, {
        structures: { scope },
      });

      this.$emit("changedSubScope", scope);
    },
    navigateToSubReport() {
      const to = {
        name: this.$route.name,
        query: {
          scope: this.selectedSubScope,
          [this.parentScope]: this.itemId,
        },
      };
      this.$router.push(to);
    },
  },
  mounted() {
    // this.$store.dispatch(this.instance.p.a.fetch);
  },
};
</script>

<style lang="scss" scoped>
.SubTable {
  border-radius: 0;
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    pointer-events: none;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.v-btn.v-btn--text.primary--text.subSettingsBtn {
  height: auto;
  border-radius: 0;
  padding: 12px 0;

  .currentScope {
    position: relative;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    line-height: 0.8;
    margin-top: 16px;
  }
}
</style>
