<template>
  <v-dialog v-bind="dialogProps">
    <template #default="{value}">
      <!-- Fullscreen -->
      <template v-if="fullscreen">
        <v-toolbar
          dark
          color="primary"
          :height="$vuetify.breakpoint.smAndDown ? 72 : 48"
        >
          <v-container
            class="centeredTitle pa-0"
            :class="{ smAndDown: $vuetify.breakpoint.smAndDown }"
          >
            <div class="toolbarLeft">
              <slot name="closeButton" :click="modalConf.close">
                <v-btn @click="modalConf.close" text class="justify-self-start">
                  <slot name="closeButtonText">
                    <v-icon class="mr-2" small>mdi-arrow-left-circle</v-icon
                    >Close
                  </slot>
                </v-btn>
              </slot>
            </div>
            <v-toolbar-title>
              <slot name="title" />
            </v-toolbar-title>
            <v-toolbar-items class="toolbarRight" v-if="$slots.right">
              <slot name="right"></slot>
            </v-toolbar-items>
          </v-container>
        </v-toolbar>
        <v-container v-if="value" class="p-0">
          <widget-v2 v-bind="$attrs" />
        </v-container>
      </template>

      <!-- Non Fullscreen -->
      <v-sheet v-else-if="value">
        <widget-v2 v-bind="$attrs" />
      </v-sheet>
    </template>

    <template
      v-if="$scopedSlots.activator"
      slot="activator"
      slot-scope="slotData"
    >
      <slot name="activator" v-bind="slotData" />
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "WidgetModal",
  props: {
    activator: { default: null },
    width: { default: null },
    fullscreen: { default: false },
  },
  computed: {
    dialogProps() {
      return {
        activator: this.activator,
        width: this.width,
        fullscreen: this.fullscreen,
      };
    },
  },
};
</script>
