<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Resident Trends

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <v-row>
      <v-col cols="3">
        <table>
          <tbody>
            <tr>
              <td class="text-caption">Viewing By:</td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn color="primary" class="mr-2" v-on="on" text small>
                      {{ selectedScope.label }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="selectedScopeIndex"
                      color="primary"
                    >
                      <v-list-item v-for="s in scopes" :key="s.value">
                        {{ s.label }}
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="pr-3 text-caption">
                Primary Metric:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      {{ selectedMetric.label }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="metric in metrics"
                      :key="metric.value"
                      @click="changeMetric(metric)"
                    >
                      <v-list-item-content>{{
                        metric.label
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr>
              <td class="pr-3 text-caption">
                Time Frame:
              </td>
              <td>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn v-on="on" color="primary" small text>
                      {{ snakeToSentenceCase(timeframe) }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="TF in timeframes"
                      :key="TF"
                      @click="changeTimeframe(TF)"
                    >
                      <v-list-item-content>{{
                        snakeToSentenceCase(TF)
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="9">
        <v-expand-transition>
          <card v-if="selected.length">
            <table-timelines
              :instance="instance"
              :selected="selected"
              :selectedMetric="selectedMetric"
              :labelFn="selectedTimeframe.labelFn"
              :showSwiftBunnyIndex="showSwiftBunnyIndex"
              :showClientIndex="showClientIndex"
            />
          </card>
        </v-expand-transition>
        <v-expand-transition>
          <list-stats-cards
            v-if="!selected.length"
            v-bind="{ statCards, table }"
          />
        </v-expand-transition>
      </v-col>
    </v-row>

    <v-card class="mt-3">
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
        show-select
        v-model="selected"
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>
        <template #header.data-table-select="{props: {value}, on: {input}}">
          <v-btn @click="input(!value)" color="primary" icon>
            <cp-icon-stack
              v-if="value"
              :icons="['mdi-chart-timeline-variant', 'mdi-close']"
            />
            <v-icon v-else>mdi-chart-timeline-variant-shimmer</v-icon>
          </v-btn>
        </template>

        <template #item.data-table-select="{isSelected, select}">
          <v-btn @click.stop="select(!isSelected)" color="primary" icon>
            <cp-icon-stack
              v-if="isSelected"
              :icons="['mdi-chart-timeline-variant', 'mdi-minus']"
            />
            <cp-icon-stack
              v-else
              :icons="['mdi-chart-timeline-variant', 'mdi-plus']"
            />
          </v-btn>
        </template>

        <template v-for="n of 20" :slot="`item.t_${n}`" slot-scope="x">
          <span :key="`${x.item.id}-timeline-${n}`">
            {{
              x.header.format
                ? x.header.format(x.item.timeline[x.header.path[0]] || {})
                : get(x.item.timeline, x.header.path)
            }}{{ x.header.append ? x.header.append : "" }}
          </span>
        </template>

        <template #item.metric="x">
          <pre v-if="!x.item">{{ JSON.stringify(x) }}</pre>
          <span v-else :key="`${x.item.id}-metric-${selectedMetric.value}`">
            {{
              x.header.format
                ? x.header.format(x.item)
                : x.item[selectedMetric.value]
            }}{{ x.header.append ? x.header.append : "" }}
          </span>
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { onCpEvent } from "@cp/mixins";
import { snakeToSentenceCase } from "@cp/utils/stringUtils";

import {
  widgetMixin,
  tableMixin,
  multiScopeMixin,
  timelinesMixin,
} from "@/widgets/mixins";

import { icons, colors } from "@/lib/colorsAndIcons";

export default {
  mixins: [
    widgetMixin,
    tableMixin,
    timelinesMixin,
    multiScopeMixin,
    onCpEvent("multiScopeMixin:scopeChanged", function() {
      this.resetSelected();
    }),
  ],
  computed: {
    scopeHeaders() {
      return {
        default: [{ text: "", value: "name" }, ...this.timelineHeaders],
      };
    },
    statCards() {
      const cards = [
        {
          title: "Scores",
          icon: "target",
          stats: [
            [
              "Average Score",
              "agreement_importance",
              { icon: "thumb-up", iconProps: { color: "success" } },
            ],
            ["Portfolio Average", "client_index.agreement_importance"],
            ["Swift Bunny index", "sb_index.agreement_importance"],
          ],
        },
      ];
      if (this.scope === "surveys") {
        cards.push(
          {
            title: "Satisfaction",
            icon: "emoticon-happy-outline",
            stats: [
              [
                "Likely to Renew",
                ({
                  likely_to_renew_responses: a,
                  likely_to_renew_top_block: b,
                }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "emoticon-happy",
                  iconProps: { color: "success" },
                },
              ],
              ["Answered likelyhood", "likely_to_renew_responses"],
              ["Likely to renew", "likely_to_renew_top_block"],
            ],
          },
          {
            title: "Survey Response",
            icon: "file-document-edit-outline",
            stats: [
              [
                "Response Rate",
                ({ surveys_responded: a, surveys_sent: b }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "percent-outline",
                  iconProps: { color: "success" },
                },
              ],
              ["Surveys Sent", "surveys_sent"],
              ["Responded", "surveys_responded"],
              ["Surveys Completed", "surveys_completed"],
            ],
          }
        );
      } else {
        cards.push(
          {
            title: "Portfolio",
            icon: "home-city",
            stats: [
              [
                "Areas",
                "area_count",
                {
                  showIfEmpty: false,
                  icon: icons.district,
                  iconProps: { color: colors.district },
                },
              ],
              [
                "Regions",
                "region_count",
                {
                  showIfEmpty: false,
                  icon: icons.region,
                  iconProps: { color: colors.region },
                },
              ],
              [
                "Locations",
                "location_count",
                { icon: icons.property, iconProps: { color: colors.property } },
              ],
              ["Residents", "resident_count"],
            ],
          },
          {
            title: "Satisfaction",
            icon: "emoticon-happy-outline",
            stats: [
              [
                "Likely to Renew",
                ({
                  likely_to_renew_responses: a,
                  likely_to_renew_top_block: b,
                }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "emoticon-happy",
                  iconProps: { color: "success" },
                },
              ],
              ["Surveys Sent", "surveys_sent"],
              [
                "Response Rate",
                ({ surveys_responded: a, surveys_sent: b }) => b / a,
                { format: this.coaxRoundPrct },
              ],
            ],
          }
        );
      }
      return cards;
    },
  },
  methods: { snakeToSentenceCase },
};
</script>
