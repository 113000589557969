import CoaxRoundPrctCell from "./CoaxRoundPrctCell.vue";
import ProperizeCell from "./ProperizeCell.vue";
import RoundCell from "./RoundCell.vue";
import QuestionTypeCell from "./QuestionTypeCell.vue";

export default {
  agreement_importance: RoundCell,
  "item.agreement_importance": RoundCell,
  likely: CoaxRoundPrctCell,
  "item.likely": CoaxRoundPrctCell,
  likely_to_renew_percentage: CoaxRoundPrctCell,
  "item.likely_to_renew_percentage": CoaxRoundPrctCell,
  likely_to_remain_percentage: CoaxRoundPrctCell,
  "item.likely_to_remain_percentage": CoaxRoundPrctCell,
  response_rate: CoaxRoundPrctCell,
  "item.response_rate": CoaxRoundPrctCell,
  question_type_slug: QuestionTypeCell,
  "item.question_type_slug": QuestionTypeCell,
};
