<template>
  <div v-if="hasData">
    <cp-h1 hide-back-button disable-set-document-title>
      Task Compliance
      <template #subtitle> Average Score: {{ compliantPercentage }} </template>
    </cp-h1>
    <v-card>
      <v-sheet class="d-flex align-stretch v-card__text pa-0">
        <v-row no-gutters class="align-center">
          <summary-line-chart id="engagement-line" v-bind="chartProps" />
          <v-col>
            <div class="d-flex align-center justify-center">
              <pie-chart :height="90" :width="90" v-bind="statusPie" />
              <table class="ml-4">
                <tbody>
                  <template v-for="key in statusKeys">
                    <tr v-if="s[key]" :key="key">
                      <td class="stat-label pr-1">
                        <v-icon :color="stati[key].color" small
                          >mdi-circle</v-icon
                        >
                      </td>
                      <td class="stat-label text-right">
                        {{ stati[key].label }}
                      </td>
                      <td class="text-right pl-3">{{ s[key] }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";

import { get } from "@cp/utils/objectUtils";
import { nonEmpty } from "@cp/utils/itertees";
import { mapStrToQLabel } from "@/lib/quarterOfYear";

import widgetMixin from "@/widgets/mixin";
import { time } from "vue-gtag";
// this is the rth incarnation of statuses. the other 3 are:
// @/src/store/modules/tasks.js:72 - used to make buttons on task form
// @/src/store/modules/employee.js:104 - used to display tasks in a table
// @/src/widgets/Task/ComplianceSummaryReport.vue - used to build similar UI
// THIS will be used to categorize compliance status. Because:
// TODO - get michael to explain WHY... AGAIN
const stati = {
  compliant: { label: "Compliant", color: "#61a715" },
  non_compliant: { label: "Non-Compliant", color: "#CCCCCC" },
  ontime: { label: "On Time", color: "#61a715" },
  cant_complete: { label: "Can't Complete", color: "#FBAF57" },
  past_due: { label: "Late", color: "#E40000" },
  abandoned: { label: "Abandoned", color: "#8B5DCB" },
  canceled: { label: "Canceled", color: "#CCCCCC" },
  users_task_count: { label: "Assignments" },
  task_count: { label: "Unique Tasks" },
  onboarding_task_count: { label: "Onboarding Tasks" },
  offboarding_task_count: { label: "Offboarding Tasks" },
};

const statusKeys = [
  "ontime",
  "past_due",
  "cant_complete",
  "abandoned",
  "canceled",
];

export default {
  components: { Scrollbar },
  mixins: [widgetMixin],
  data() {
    return {
      stati,
      statusKeys,
      headers: [
        {
          text: "Least compliant tasks",
          value: "name",
          sortable: false,
        },
        {
          text: "Abandoned",
          value: "abandoned",
          width: 100,
          sortable: false,
        },
        {
          text: "Can't Complete",
          value: "cant_complete",
          width: 120,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    hasData() {
      return get(this.meta, "summary_object.task_count");
    },
    compliantPercentage() {
      const value = get(this.meta, "summary_object.compliant_percentage");
      return value ? `${Math.round(value)}%` : "";
    },
    s() {
      return Object.keys(stati).reduce((r, k) => {
        r[k] = get(this.meta, ["summary_object", k], 0);
        return r;
      }, {});
    },
    chartProps() {
      const {
        timelines,
        labels,
        yMin: minYValue,
        yMax: maxYValue,
      } = this.meta.summary_object.timeline;
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.2; // pad by 20%
      const yMin = Math.max(Math.round(parseFloat(minYValue) - yPad), 0);
      const yMax = Math.min(Math.round(parseFloat(maxYValue) + yPad), 100);

      const datasets = [];

      const mapCoords = (y, x) => ({ x, y: parseFloat(y) });
      const filterCoords = ({ y }) => nonEmpty(y);

      if (timelines.timeline) {
        datasets.push({
          fill: true,
          borderColor: "rgba(230, 126, 34, 1)",
          backgroundColor: "rgba(230, 126, 34, .1)",
          pointBackgroundColor: "rgba(230, 126, 34, 1)",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.timeline.map(mapCoords).filter(filterCoords),
          label: this.parent.name,
        });
      }

      return {
        // modalProps: {
        //   url: `properties/${this.$route.params.id}/employee_engagement_trends`,
        // },
        chartData: {
          labels: labels.map(mapStrToQLabel),
          datasets,
          yMin,
          yMax,
        },
        options: {
          spanGaps: true,
        },
        lineChartProps: {
          yMin,
          yMax,
        },
      };
    },
    statusPie() {
      const data = [];
      const labels = [];
      const backgroundColor = [];
      statusKeys.forEach(key => {
        if (this.s[key]) {
          data.push(this.s[key]);
          labels.push(stati[key].label);
          backgroundColor.push(stati[key].color);
        }
      });
      return {
        chartData: {
          labels,
          datasets: [{ data }],
        },
        options: {
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
          },
          backgroundColor,
          // hoverBackgroundColor: backgroundColor,
        },
      };
    },
    stats() {
      return [
        {
          id: "engagement-likely-to-remain",
          label: "Onboarding",
          value: this.data.onboarding_plans,
        },
        {
          id: "engagement-surveys-sent",
          label: "Offboarding",
          value: this.data.offboarding_plans,
        },
        {
          id: "engagement-tenure",
          label: "Tasks Overdue",
          value: this.data.past_due,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}

.leastCompliantTasks::v-deep {
  td:first-child,
  th:first-child {
    width: 170px;
    max-width: 170px;
  }
}

.leastCompliantTasks::v-deep .v-data-table__wrapper table {
  min-height: 120px;
}

.ps {
  position: relative;
  max-height: 120px;
}
</style>
