<template>
  <div class="expandable-table__wrapper">
    <cp-h1 css-class="align-center" right-class="align-center flex-grow-1 ml-8">
      Task Compliance Report
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'widget_docs',
            params: {
              category: 'properties',
              widget: 'employee_compliance',
              id: $router.currentRoute.params.id,
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="Stats">
      <card
        class="StatsCard flex-grow-1 d-flex flex-column"
        title="Compliance"
        icon="target"
        root-slot
      >
        <v-card-text
          class="mt-3 flex-grow-1 d-flex align-center justify-space-around"
        >
          <div class="d-flex flex-column align-center justify-center">
            <cp-doughnut size="80px" :value="complianceDoughnut">
              <template #inside>
                <span class="text-caption">{{ compliantPrct || "N/A" }}</span>
              </template>
            </cp-doughnut>
            <div class="stat-label">Compliance</div>
          </div>
          <table>
            <tbody>
              <tr v-for="key in complianceKeys" :key="key">
                <td class="stat-label text-right">{{ stati[key].label }}</td>
                <td class="text-right">{{ s[key] }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </card>
      <card
        class="StatsCard flex-grow-1 d-flex flex-column"
        title="Task Statuses"
        icon="pencil-box-outline"
        root-slot
      >
        <v-card-text
          class="mt-3 flex-grow-1 d-flex align-center justify-space-around"
        >
          <div class="d-flex flex-column align-center">
            <pie-chart :height="90" :width="90" v-bind="statusPie" />
          </div>
          <table>
            <tbody>
              <tr v-for="key in statusKeys" :key="key">
                <td class="stat-label">
                  <v-icon :color="stati[key].color" small>mdi-circle</v-icon>
                </td>
                <td class="stat-label text-right">{{ stati[key].label }}</td>
                <td class="text-right">{{ s[key] }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </card>
      <card
        class="StatsCard flex-grow-1 d-flex flex-column"
        title="Tasks"
        icon="checkbox-multiple-marked-outline"
        root-slot
      >
        <v-card-text
          class="mt-3 flex-grow-1 d-flex align-center justify-space-around"
        >
          <div class="text-center">
            <div class="primaryValue">
              {{ s.users_task_count }}
            </div>
            <div class="primaryLabel">{{ stati.users_task_count.label }}</div>
          </div>
          <div>
            <table>
              <tbody>
                <tr v-for="key in countKeys" :key="key">
                  <td class="stat-label text-right">{{ stati[key].label }}</td>
                  <td class="text-right">{{ s[key] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </card>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ properize(scope) }}
        </template>

        <template #item.compliant_percentage="{value}">
          {{ coaxRoundPrct(value) }}
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

// this is the 3rd incarnation of statuses. the other two are:
// @/src/store/modules/tasks.js:72 - used to make buttons on task form
// @/src/store/modules/employee.js:104 - used to display tasks in a table
// THIS will be used to categorize compliance status. Because:
// TODO - get michael to explain WHY... AGAIN
const stati = {
  compliant: { label: "Compliant", color: "#61a715" },
  non_compliant: { label: "Non-Compliant", color: "#CCCCCC" },
  ontime: { label: "On Time", color: "#61a715" },
  cant_complete: { label: "Can't Complete", color: "#FBAF57" },
  past_due: { label: "Late", color: "#E40000" },
  abandoned: { label: "Abandoned", color: "#8B5DCB" },
  canceled: { label: "Canceled", color: "#CCCCCC" },
  users_task_count: { label: "Assignments" },
  task_count: { label: "Unique Tasks" },
  onboarding_task_count: { label: "Onboarding Tasks" },
  offboarding_task_count: { label: "Offboarding Tasks" },
};

const complianceKeys = ["compliant", "non_compliant"];
const statusKeys = [
  "ontime",
  "past_due",
  "cant_complete",
  "abandoned",
  "canceled",
];
const countKeys = [
  "task_count",
  "onboarding_task_count",
  "offboarding_task_count",
];

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return {
      stati,
      complianceKeys,
      statusKeys,
      countKeys,
    };
  },
  computed: {
    scopeHeaders() {
      return {
        default: [
          { text: "", value: "name" },
          { text: "Compliance", value: "compliant_percentage", width: 125 },
          {
            text: this.scope === "tasks" ? "Assignments" : "Tasks",
            value: "users_task_count",
            width: this.scope === "tasks" ? 135 : 90,
          },
          { text: "On Time", value: "ontime", width: 105 },
          { text: "Late", value: "late", width: 80 },
          { text: "Can't Complete", value: "cant_complete", width: 145 },
          { text: "Abandoned", value: "abandoned", width: 120 },
          { text: "Canceled", value: "canceled", width: 110 },
        ],
      };
    },
    statCards() {
      const cards = [
        {
          title: "Scores",
          icon: "target",
          stats: [
            ["Average Score", "agreement_importance"],
            ["Portfolio Average", "client_index.agreement_importance"],
            ["Swift Bunny index", "sb_index.agreement_importance"],
          ],
        },
      ];
      return cards;
    },
    s() {
      return Object.keys(stati).reduce((r, k) => {
        r[k] = get(this.meta, ["summary_object", k], 0);
        return r;
      }, {});
    },
    complianceDoughnut() {
      const backgroundColor = [
        stati.compliant.color,
        stati.non_compliant.color,
      ];
      return {
        data: [this.s.compliant, this.s.non_compliant],
        backgroundColor,
        borderWidth: 0,
        cutout: "60%",
        hoverBackgroundColor: backgroundColor,
      };
    },
    statusPie() {
      const data = [];
      const labels = [];
      const backgroundColor = [];
      statusKeys.forEach(key => {
        if (this.s[key]) {
          data.push(this.s[key]);
          labels.push(stati[key].label);
          backgroundColor.push(stati[key].color);
        }
      });
      return {
        chartData: {
          labels,
          datasets: [{ data }],
        },
        options: {
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
          },
          backgroundColor,
          // hoverBackgroundColor: backgroundColor,
        },
      };
    },
    compliantPrct() {
      const total = this.s.compliant + this.s.non_compliant;
      const prct = Math.round((this.s.compliant / total) * 100);
      return prct ? `${prct}%` : "N/A";
    },
  },
};
</script>

<style lang="scss" scoped>
.Stats {
  display: flex;
  align-items: stretch;
  gap: 12px;
  margin-bottom: 12px;
  overflow: auto;
}

.stat-label {
  white-space: nowrap;
}
::v-deep th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.stat-label {
  padding-right: 6px;
  vertical-align: middle;

  .v-icon {
    // Holy crap. something weird with .v-icon in <td> make it not v-align right
    display: inline-block;
    position: relative;
    top: -3px;
  }
}

.primaryValue {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
</style>
