var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expandable-table__wrapper"},[_c('cp-h1',{scopedSlots:_vm._u([(_vm.devMode)?{key:"subtitle",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'widget_docs',
          params: {
            category: 'regions',
            widget: 'employee_development',
            id: _vm.$router.currentRoute.params.id,
          },
          query: _vm.$router.currentRoute.query,
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-code-greater-than")]),_vm._v(" docs ")],1)]},proxy:true}:null,{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table,"noun":"Report"}})]},proxy:true}],null,true)},[_vm._v(" Onboarding and Offboarding ")]),_c('div',{staticClass:"card-row"},[_c('div',{staticClass:"card-row-cards"},[_c('stat-cards',_vm._b({},'stat-cards',{ statCards: _vm.statCards, table: _vm.table },false))],1)]),_c('v-card',[_c('v-data-table',_vm._b({attrs:{"item-key":"m_id","hide-default-footer":""},on:{"update:options":_vm.updateTableOptions},scopedSlots:_vm._u([{key:"item.complete_percentage",fn:function({value}){return [_vm._v(" "+_vm._s(value ? _vm.coaxRoundPrct(value) : "0%")+" ")]}}])},'v-data-table',_vm.tableProps,false)),(!_vm.noData)?_c('v-pagination',_vm._b({staticClass:"mt-2 text-center",on:{"input":_vm.changePage}},'v-pagination',_vm.paginationProps,false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }