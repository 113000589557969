var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expandable-table__wrapper"},[_c('cp-h1',{scopedSlots:_vm._u([(_vm.devMode)?{key:"subtitle",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'widget_docs',
          params: {
            category: 'regions',
            widget: 'resident_reasons_leaving_report',
            id: _vm.$router.currentRoute.params.id,
          },
          query: _vm.$router.currentRoute.query,
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-code-greater-than")]),_vm._v(" docs ")],1)]},proxy:true}:null,{key:"right",fn:function(){return [_c('cp-filters-menu',{attrs:{"module":_vm.table,"noun":"Report"}})]},proxy:true}],null,true)},[_vm._v(" Reasons Residents Are Leaving ")]),_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"mc-responses"},[(!_vm.total || _vm.total <= 0)?_c('div',{staticClass:"font-weight-bold text-center mt-3"},[_vm._v(" No Responses ")]):[_c('h5',{staticClass:"mc-response-header"},[_vm._v(" Total Responses: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.total))])]),_vm._l((_vm.responses),function(response,index){return _c('div',{key:index,staticClass:"mc-response-wrapper"},[_c('h5',{staticClass:"mc-response-text"},[_vm._v(" "+_vm._s(response.value)),_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(" - "+_vm._s(response.label))]),_vm._v(" - "+_vm._s(_vm.totalPercentage(response.value))+"% ")]),_c('div',{staticClass:"mc-response-bar",style:(`width: ${_vm.percentage(
                  response.value
                )}%; background-color: ${_vm.getColor(response)};`)})])})]],2)])],1),_c('card',{staticClass:"mt-6",attrs:{"title":"Other responses to: Why aren't you very likely to renew?","icon":"chat-question-outline"}},[_vm._l((_vm.data),function(r,i){return _c('div',{key:i},[(r.other_text_response)?_c('v-alert',{staticClass:"py-1",attrs:{"icon":"mdi-message-outline","color":"blue-grey","text":""}},[_c('div',{staticClass:"commentQuestion text-caption d-flex align-center"},[(r.location_name)?_c('span',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(r.location_name)+" "),_c('span',{staticClass:"mx-3"},[_vm._v("|")])],1):_vm._e(),_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(r.first_name)+" "+_vm._s(r.last_name)+" "),_c('span',{staticClass:"mx-3"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.toMDY(r.created_at))+" ")],1),_vm._v(" "+_vm._s(r.other_text_response)+" ")]):_vm._e()],1)}),_c('v-pagination',_vm._g(_vm._b({staticClass:"mt-2 text-center"},'v-pagination',_vm.paginationProps,false),_vm.paginationListeners))],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }