<template>
  <v-row>
    <v-col cols="4">
      <cp-h1>
        Form
        <template #right>
          <v-btn @click="calculator.methods.reset" color="white" small>
            <v-icon left>mdi-cancel</v-icon>
            Reset
          </v-btn>
        </template>
      </cp-h1>
      <v-card>
        <v-progress-linear
          v-if="!filters.length && !structures.length"
          indeterminate
        />
        <v-expansion-panels v-model="formPanels" multiple accordion>
          <v-expansion-panel v-if="filters.length">
            <v-expansion-panel-header>
              Filters
              <span
                v-if="
                  payload.filters && Object.keys(payload.filters || {}).length
                "
                class="ml-4"
              >
                ({{ Object.keys(payload.filters).length }})
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                  v-for="filter in filters"
                  :key="`filter-item-${filter.key}`"
                  class="px-1"
                >
                  <v-autocomplete
                    v-if="filter.search"
                    v-bind="filter.attrs"
                    @input="filter.input"
                    @update:search-input="filter.search"
                    @focus="filter.focus"
                    :search-input="null"
                    :multiple="filter.type === 'multi'"
                  />
                  <v-select
                    v-else-if="filter.type === 'select'"
                    v-bind="filter.attrs"
                    @input="filter.input"
                  />

                  <v-text-field
                    v-if="filter.type === 'text'"
                    :prepend-inner-icon="
                      filter.key === 'q_text' ? 'mdi-magnify' : ''
                    "
                    v-bind="filter.attrs"
                    @input="filter.input"
                  />

                  <v-checkbox
                    v-if="filter.type === 'checkbox'"
                    v-bind="filter.attrs"
                    @change="filter.input"
                  />

                  <v-radio
                    v-if="filter.type === 'radio'"
                    v-bind="filter.attrs"
                    @change="filter.input"
                  />
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="structures.length">
            <v-expansion-panel-header>
              Structures
              <span
                v-if="
                  payload.structures &&
                    Object.keys(payload.structures || {}).length
                "
                class="ml-4"
              >
                ({{ Object.keys(payload.structures).length }})
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-for="(structure, i) in structures">
                <v-select
                  :key="`structure-${i}-select`"
                  v-bind="structure.attrs"
                  @input="structure.input"
                />
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <div class="d-flex space-between align-center mt-2">
        <v-menu v-if="sorts.length" content-class="sort-menu">
          <template #activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-sort</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item v-for="{ value, text } in sorts" :key="value">
                <v-list-item-content>{{ text }}</v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle
                    :value="
                      payload.sort_by !== value
                        ? null
                        : payload.sort_desc
                        ? 1
                        : 0
                    "
                  >
                    <v-btn @click="sort(value, false)" small color="white">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn @click="sort(value, true)" small color="white">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-data-footer
          v-bind="dataOptions"
          @update:options="calculator.methods.changeDataOptions"
        />
      </div>
    </v-col>

    <v-col cols="3">
      <cp-h1>
        Request
        <template #right>
          <v-btn @click="fetch" color="white" small>
            <v-icon left>mdi-reload</v-icon>
            Resend
          </v-btn>
        </template>
      </cp-h1>
      <v-card :loading="loading">
        <v-card-text>
          <strong>Url: </strong>
          <span>{{ url }}</span>
          <br />
          <strong>Payload:</strong>
          <br />
          <VueJsonPretty :data="payload" @node-click="handleNodeClick" />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="5">
      <cp-h1>Response</cp-h1>
      <v-card>
        <v-progress-linear v-if="loading" indeterminate />
        <v-expansion-panels v-model="responsePanels" multiple accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Data:
              <span v-if="response.data.length" class="ml-4">
                ({{ response.data.length }})
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="responseJson">
                <vue-json-pretty
                  :data="response.data"
                  @node-click="handleNodeClick"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="!isEmpty(response.addons)">
            <v-expansion-panel-header>Addons:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="responseJson">
                <vue-json-pretty
                  :data="response.addons"
                  @node-click="handleNodeClick"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Meta:</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="responseJson">
                <vue-json-pretty
                  :data="response.meta"
                  @node-click="handleNodeClick"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import { get, isEmpty } from "@cp/utils/objectUtils";
import { pathToUrl } from "carrot-patch-v2/src/utils/pathToUrl";
import { nonEmpty } from "carrot-patch-v2/src/utils/itertees";

export default {
  name: "Calculator",
  components: { VueJsonPretty },
  props: {
    calculator: { type: Object, required: true },
    defaultOpenResponsePanel: { default: 0 },
    params: { type: Object },
  },
  data() {
    return {
      responsePanels: [this.defaultOpenResponsePanel],
      formPanels: [],
    };
  },
  computed: {
    url() {
      let path = "";
      if (this.calculator.url) {
        if (this.calculator.urlTemplate && nonEmpty(this.params)) {
          path = pathToUrl(this.calculator.url, this.params);
        } else {
          path = this.calculator.url;
        }
      }
      return `${this.calculator.baseUrl}${path}`;
    },
    loading() {
      return get(this.$store.state, this.calculator.p.s.loading, false);
    },
    payload() {
      return this.$store.getters[this.calculator.p.g.payload];
    },
    response() {
      return get(this.$store.state, this.calculator.p.s.stateKey, []);
    },
    filters() {
      return get(this.$store.state, this.calculator.p.s.filters);
    },
    structures() {
      return get(this.$store.state, this.calculator.p.s.structures);
    },
    sorts() {
      return get(this.$store.state, this.calculator.p.s.sorts);
    },
    dataOptions() {
      const sortBy = this.payload.sort_by;
      const sortDesc = this.payload.sort_desc;
      const page = this.payload.page.number;
      const itemsPerPage = this.payload.page.size;
      const pMeta = get(
        this.$store.state,
        this.calculator.p.s.meta + ".pagination"
      );
      return {
        options: {
          sortBy,
          sortDesc,
          page,
          itemsPerPage,
        },
        pagination: {
          page,
          itemsPerPage,
          pageStart: (page - 1) * itemsPerPage,
          pageStop: page * itemsPerPage,
          itemsLength: pMeta.record_count,
          pageCount: pMeta.last || 1,
        },
      };
    },
  },
  methods: {
    isEmpty,
    sort(sort_by, sort_desc) {
      this.$store.dispatch(this.calculator.p.a.updateParams, {
        sort_by,
        sort_desc,
      });
    },
    handleNodeClick(arg) {
      if (!navigator) return;
      const content = arg.content;
      navigator.clipboard.writeText(content).then(
        function() {
          console.log(`Coppied to clipboard: ${content}`);
        },
        function(err) {
          console.error("Copy to clipboard failed!");
          console.error(err);
        }
      );
    },
    async fetch() {
      await this.calculator.methods.fetch(this.params);
      this.$emit("loaded", { url: this.url });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-expansion-panel-header--active {
    background: rgba($primary, 0.15);
  }
  .v-expansion-panel-content__wrap {
    padding-left: 3px;
    padding-right: 3px;
  }
  .vjs-tree-node {
    cursor: pointer;
  }
  .vjs-indent + span:not(.vjs-key),
  .vjs-key + span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .vjs-tree-node:hover {
    .vjs-indent ~ span:not(.vjs-key),
    .vjs-key ~ span {
      overflow: visible;
      background: #e6f7ff;
    }
  }
  .v-data-footer__ {
    &select,
    &pagination,
    &icons-before,
    &icons-after {
      margin-left: 0;
      margin-right: 0;
    }
    &select .v-input {
      margin-left: 10px;
    }
  }
}

.v-data-footer {
  justify-content: flex-end;
}

.sort-menu::v-deep .v-btn-toggle .v-btn--active {
  .v-icon {
    color: white !important;
  }

  &:before {
    background: $primary;
    opacity: 1;
  }
}
</style>
