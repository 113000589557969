<template>
  <div>
    <cp-h1>
      Employee Action Items Report
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'report_docs',
            params: {
              widget: 'employee_action_item_report',
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <!-- <list-stats-cards v-bind="{ statCards, table }" /> -->

    <v-card>
      <v-data-table
        v-bind="tableProps"
        @update:options="updateTableOptions"
        :expanded="expanded"
        hide-default-footer
        show-expand
      >
        <template #item.name="{item}">
          <cp-explain root-slot>
            <v-card max-width="600px">
              <v-card-text>
                <div v-html="item.action_item.body" />
              </v-card-text>
              <v-divider horizontal />
              <v-card-actions class="d-block pa-4">
                <h6 class="text--h6">Attachments:</h6>
                <cp-file-chip
                  v-for="file in item.action_item.attachments"
                  :key="file.id"
                  :name="file.name"
                  :url="file.url"
                  class="ma-1"
                />
              </v-card-actions>
            </v-card>
          </cp-explain>
          {{ item.action_item.title }}
        </template>
        <template #item.assigned_at="{value}">
          {{ toMDY(value) }}
        </template>
        <template #item.awaiting_results_started_at="{value}">
          {{ toMDY(value) }}
        </template>
        <template #item.assignee="{item}">
          {{ item.assignee && item.assignee.first_name }}
          {{ item.assignee && item.assignee.last_name }}
        </template>

        <template #expanded-item="{item}">
          <td :colspan="headers.length + 1" class="py-4">
            <p>
              <template v-if="item.creator">
                <strong>
                  {{ item.creator.first_name }}
                  {{ item.creator.last_name }}
                </strong>
                assigned this to
              </template>
              <template v-else>
                Assigned to
              </template>

              <strong v-if="item.assignee">
                {{ item.assignee.first_name }}
                {{ item.assignee.last_name }}
              </strong>
              <strong v-else>(No longer in the system)</strong>
              on
              <strong>{{ toMDY(item.assigned_at) }}</strong
              >.
            </p>
            <p v-if="item.completed_at">
              Completed on <strong>{{ toMDY(item.completed_at) }}</strong
              >.
            </p>
            <p>
              The scores below show what your target regions and positions were
              at when this assignment was started, next to what we recorded
              after your follow-up surveys were completed.
            </p>
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <v-data-table
                    :items="item.target_regions"
                    :headers="[subHeaders.region, ...subHeaders.rest]"
                    hide-default-footer
                  >
                    <template #item.old_score="{value}">{{
                      value || "N/A"
                    }}</template>
                    <template
                      #item.direction="{item: {old_score: o, new_score: n}}"
                    >
                      <template v-if="o && n">
                        <v-icon v-if="o < n" color="success">
                          mdi-trending-up
                        </v-icon>
                        <v-icon v-else-if="o === n">
                          mdi-trending-neutral
                        </v-icon>
                        <v-icon v-else-if="o > n" color="error">
                          mdi-trending-down
                        </v-icon>
                      </template>
                    </template>
                    <template #item.new_score="{value}">{{
                      value || "N/A"
                    }}</template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <v-data-table
                    :items="item.target_positions"
                    :headers="[subHeaders.position, ...subHeaders.rest]"
                    hide-default-footer
                  >
                    <template #item.old_score="{value}">{{
                      value || "N/A"
                    }}</template>
                    <template
                      #item.direction="{item: {old_score: o, new_score: n}}"
                    >
                      <template v-if="o && n">
                        <v-icon v-if="o < n" color="success">
                          mdi-trending-up
                        </v-icon>
                        <v-icon v-else-if="o === n">
                          mdi-trending-neutral
                        </v-icon>
                        <v-icon v-else-if="o > n" color="error">
                          mdi-trending-down
                        </v-icon>
                      </template>
                    </template>
                    <template #item.new_score="{value}">{{
                      value || "N/A"
                    }}</template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  mixins: [widgetMixin, tableMixin],
  data() {
    return {
      expanded: [],
      headers: [
        { text: "Priority", value: "topic.body" },
        { text: "Action Item", value: "name" },
        { text: "Date", value: "assigned_at", width: 120 },
        { text: "Assignee", value: "assignee" },
        { text: "Status", value: "current_status" },
      ],
      subHeaders: {
        region: { text: "Region", value: "name" },
        position: { text: "Position", value: "name" },
        rest: [
          { text: "Start", value: "old_score", width: 80 },
          {
            text: "",
            value: "direction",
            width: 24,
            sortable: false,
            class: "px-0",
            cellClass: "px-0",
          },
          { text: "End", value: "new_score", width: 80 },
        ],
      },
    };
  },
};
</script>
