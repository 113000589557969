<template>
  <summary-ai-histogram
    v-if="type === 'agreement-importance' || hasAISummary"
    v-bind="$props"
  />
  <!-- TODO this is atrocious, requires rewrite -->
  <widget-modal
    v-else-if="type === 'text-entry'"
    :url="`/reports/resident_question_report/${id}`"
    :params="widgetParams"
  >
    <template #activator="{on}">
      <v-btn v-on="on" color="primary" small>
        <v-icon left>mdi-message-outline</v-icon>
        {{ item.response_count }}
        Comments
      </v-btn>
    </template>
  </widget-modal>
  <div v-else-if="type === 'yes-no'" class="yesNo">
    <span>Yes&nbsp;</span>
    <div class="bars" :set="([n, y] = item.response_summary)">
      <div
        class="bar-yes"
        :style="{ width: `${y}%` }"
        :class="{ smaller: y < n }"
      >
        {{ roundPrct(y) }}
      </div>
      <div
        class="bar-no"
        :style="{ width: `${n}%` }"
        :class="{ smaller: y > n }"
      >
        {{ roundPrct(n) }}
      </div>
    </div>
    <span>&nbsp;No</span>
  </div>
  <summary-liklihood-histogram
    v-else-if="type === 'likelihood'"
    v-bind="$props"
  />
  <widget-modal
    v-else-if="['multiple-select', 'multiple-choice'].includes(type)"
    :url="`/reports/resident_question_report/${id}`"
    :params="widgetParams"
  >
    <template #activator="{on}">
      <v-btn v-on="on" color="primary" small>
        View Responses
      </v-btn>
    </template>
  </widget-modal>
  <div v-else></div>
</template>

<script>
import { filterObject, get } from "@cp/utils/objectUtils";
import { nonEmpty } from "@cp/utils/itertees";
import { roundPrct } from "@/lib/formats";

export default {
  props: {
    item: { type: Object, required: true },
    table: { type: Object, required: true },
  },
  computed: {
    id() {
      return this.item.id || this.item.m_id;
    },
    type() {
      return this.item.question_type_slug;
    },
    hasAISummary() {
      return (
        get(this.item, "response_summary.agreement") &&
        get(this.item, "response_summary.importance")
      );
    },
    widgetParams() {
      const filterValues = this.$store.getters[this.table.p.g.filterValues];
      return {
        ...filterObject(filterValues, nonEmpty),
        scope: "responses",
      };
    },
  },
  methods: {
    roundPrct,
  },
};
</script>

<style lang="scss" scoped>
.yesNo {
  display: flex;
  align-items: center;
  min-width: 160px;

  .bars {
    min-width: 40px;
    flex-grow: 1;
    text-align: center;
    position: relative;
    line-height: 2.2em;
    display: flex;

    > div {
      padding: 0 4px;
      white-space: nowrap;
      overflow: visible;
      z-index: 1;
      &.smaller {
        z-index: 2;
      }
    }

    .bar-yes {
      background: $info;
      color: white;
    }
    .bar-no {
      background: $gray-3;
      color: white;
    }
  }
}
</style>
