<template>
  <div>
    <cp-h1>
      Employee Tenure Report
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'report_docs',
            params: {
              widget: 'employee_tenure',
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #item.start_date="{value}">
          {{ toMDY(value) }}
        </template>
        <template #item.last_archived_at="{value}">
          {{ value ? toMDY(value) : "" }}
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

const scopeHeaders = {
  default: [
    { text: "Name", value: "name" },
    { text: "Employees", value: "employee_count" },
    { text: "Tenure", value: "str_short" },
  ],
  employees: [
    { text: "Name", value: "name" },
    { text: "Position", value: "position" },
    { text: "Location", value: "location" },
    { text: "First Day", value: "start_date" },
    { text: "Last Day", value: "last_archived_at" },
    { text: "Tenure", value: "str_short" },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return { scopeHeaders };
  },
};
</script>
