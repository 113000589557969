<template>
  <div>
    <cp-h1 hide-back-button disable-set-document-title>
      Employee Engagement
      <template #subtitle>
        Average Score: {{ Math.round(data.current_score) }}
      </template>
    </cp-h1>
    <v-card>
      <template #title>
        <span class="ml-3"> </span>
      </template>
      <div class="d-flex align-stretch">
        <summary-line-chart id="engagement-line" v-bind="chartProps" />
        <v-row no-gutters>
          <v-col
            class="Stat text-center"
            v-for="stat in stats"
            :key="stat.id"
            :id="stat.id"
            :class="{ clickableStat: stat.modalProps || stat.to }"
            @click="stat.to && $router.push(stat.to)"
          >
            <div class="value">
              {{ nonEmpty(stat.value) ? stat.value : "N/A"
              }}<span v-if="stat.append">{{ stat.append }}</span>
            </div>
            <div class="label">
              <cp-explain v-if="stat.explainText">
                <template #activator="{on}">
                  <span v-on="on">
                    <v-icon :size="16">mdi-information-outline</v-icon>
                    <span>
                      {{ stat.label }}
                    </span>
                  </span>
                </template>
                {{ stat.explainText }}
              </cp-explain>
              <span v-else>
                <v-icon v-if="stat.icon">mdi-{{ stat.icon }}</v-icon>
                {{ stat.label }}
              </span>
            </div>
            <widget-modal
              v-if="stat.modalProps"
              v-bind="stat.modalProps"
              :activator="`#${stat.id}`"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { parse } from "@cp/utils/dateUtils";

import widgetMixin from "@/widgets/mixin";

export default {
  mixins: [widgetMixin],
  computed: {
    chartProps() {
      const {
        timelines,
        labels,
        yMin: minYValue,
        yMax: maxYValue,
      } = this.data.timelines;
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.2; // pad by 20%
      const yMin = Math.max(Math.round(parseFloat(minYValue) - yPad), 0);
      const yMax = Math.min(Math.round(parseFloat(maxYValue) + yPad), 100);

      const datasets = [];

      const mapCoords = (y, x) => ({ x, y: parseFloat(y) });
      const filterCoords = ({ y }) => y;

      if (timelines.result_timeline) {
        datasets.push({
          fill: true,
          borderColor: "rgba(230, 126, 34, 1)",
          backgroundColor: "rgba(230, 126, 34, .1)",
          pointBackgroundColor: "rgba(230, 126, 34, 1)",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.result_timeline.map(mapCoords).filter(filterCoords),
          label: this.parent.name,
        });
      }

      if (timelines.region_timeline) {
        datasets.push({
          fill: false,
          borderColor: "#009A44",
          backgroundColor: "rgba(0,60,27,.1)",
          pointBackgroundColor: "#009A44",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.region_timeline.map(mapCoords).filter(filterCoords),
          label: this.parent.region.name,
        });
      }

      if (timelines.client_timeline) {
        datasets.push({
          fill: false,
          borderColor: "#685478",
          backgroundColor: "rgba(104,84,120,.1)",
          pointBackgroundColor: "#685478",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.client_timeline.map(mapCoords).filter(filterCoords),
          label: "Company",
        });
      }

      if (timelines.sbx_timeline) {
        datasets.push({
          fill: false,
          borderColor: "#0070a8",
          backgroundColor: "rgba(0,112,168,.1)",
          pointBackgroundColor: "#0070a8",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.sbx_timeline.map(mapCoords).filter(filterCoords),
          label: "SB Index",
        });
      }

      const moYrLabels = labels.map(x => {
        const [discard, start, end] = x.split(",");
        const mo = parse(start).format("MMM");
        const startY = parse(start).format("'YY");
        const endY = parse(end).format("'YY");
        return `${mo} ${startY} - ${endY}`;
      });

      const plugins = {};
      if (datasets.length > 1) {
        plugins.legend = {
          display: true,
          labels: { usePointStyle: true, pointStyle: "line" },
          position: "bottom",
        };
      }

      return {
        modalProps: {
          url: `properties/${this.$route.params.id}/employee_engagement_trends`,
        },
        chartData: {
          labels: moYrLabels,
          datasets,
          yMin,
          yMax,
        },
        options: {
          spanGaps: true,
        },
        lineChartProps: {
          yMin,
          yMax,
        },
      };
    },
    stats() {
      return [
        {
          id: "engagement-likely-to-remain",
          label: "Likely to Remain",
          icon: "emoticon-happy-outline",
          value: this.data.likely_to_remain_prct,
          append: "%",
        },
        {
          id: "engagement-surveys-sent",
          label: "Response Rate",
          icon: "email-check-outline",
          value: this.data.response_rate_prct,
          append: "%",
        },
        {
          id: "engagement-tenure",
          label: "Tenure",
          icon: "calendar-month-outline",
          value: this.data.tenure,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}
</style>
