import { Table } from "@cp/store/mixins";
import addFromMeta from "../addons/addFromMeta";

export const portfolioTable = new Table({
  module: "portfolio",
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/portfolio`,
  name: "items",
  jsonApiResponse: false,
  params: {
    structures: {
      scope: "regions",
      addon_options: "summary",
    },
  },
  initialMeta: {
    summary_object: {},
  },
  filters: [
    "q_text",
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: 1,
    },
    {
      label: "Areas",
      type: "sub_query",
      key: "areas",
      multiple: true,
    },
    {
      label: "Region",
      type: "sub_query",
      key: "regions",
      multiple: true,
    },
    {
      label: "Reporting Groups",
      type: "sub_query",
      key: "reporting_groups_by_locations",
      multiple: true,
    },
  ],
  filtersOptions: {
    protectedQueryKeys: ["scope"],
  },
  state: {
    scopes: [
      { label: "Regions", value: "regions" },
      { label: "Locations", value: "locations" },
      { label: "Reporting Groups", value: "reporting_groups" },
    ],
  },
});

export default portfolioTable.toVuex;
