<template>
  <div class="pt-7">
    <app-action-cards />

    <v-row align="center">
      <v-col sm="6" class="d-flex align-center">
        <div style="width: 150px; height: 150px;">
          <cp-profile-avatar :profile="identity" />
        </div>
        <div class="ml-5">
          <div class="text-h6">
            {{ identity.first_name }} {{ identity.last_name }}
          </div>
          <div v-if="identity.position">{{ identity.position }}</div>
          <div v-if="identity.location">{{ identity.location }}</div>
          <div v-else-if="identity.client.name">{{ identity.client.name }}</div>

          <v-btn @click="openProfileEditModal" color="primary" outlined>
            <v-icon left>mdi-pencil</v-icon>
            Edit Profile
          </v-btn>
        </div>
      </v-col>
      <v-spacer />
      <v-col v-if="apps.length" sm="6" md="auto">
        <v-card>
          <v-card-title class="py-1 text--grey-1">
            <cp-icon icon="swiftbunny" class="mr-3" />
            My Swift Bunny Apps
          </v-card-title>
          <v-divider class="mx-4" />
          <v-list class="apps-list" dense>
            <v-list-item
              v-for="(app, i) in apps"
              :key="i"
              :href="app.href"
              two-line
              v-bind="app.listItemProps"
            >
              <v-list-item-icon>
                <cp-icon :icon="app.icon" x-large />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ app.label }}</v-list-item-title>
                <v-list-item-subtitle v-if="app.subtitle">
                  {{ app.subtitle.text }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="app.chipText">
                <v-chip v-bind="app.chipProps || {}" text small>
                  {{ app.chipText }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <cp-card titleBorderClass="mx-4" cardTitleClass="pb-1" class="mt-6 mb-5">
      <template #title>
        <div class="d-flex align-center">
          <v-icon large class="mr-2">mdi-check-decagram</v-icon>
          My Tasks
        </div>
      </template>

      <template #persistentActions>
        <div class="d-flex align-center">
          <span class="mx-2">
            Next week
          </span>
          <v-switch v-model="showNextMonth" />
          <span class="mx-2">
            Next month
          </span>
        </div>
      </template>

      <v-row v-if="!myAssignments.length && myAssignmentsLoading">
        <v-col
          v-for="i in [1, 2, 3]"
          :key="`survey-card-loading-${i}`"
          cols="12"
          md="4"
        >
          <v-skeleton-loader type="card" height="96px" />
        </v-col>
      </v-row>
      <v-row v-else-if="myAssignments.length">
        <v-col
          v-for="ass in myAssignments"
          :key="ass.id"
          cols="12"
          md="6"
          lg="4"
        >
          <assignment-card :assignment="ass" />
        </v-col>
      </v-row>
      <v-sheet v-else>
        <div class="d-flex justify-center pa-10">
          <v-alert color="success" text>
            <v-icon x-large color="success" class="mr-3">mdi-check</v-icon>
            Nothing to do. You're all caught up!
          </v-alert>
        </div>
      </v-sheet>
    </cp-card>

    <!-- removing this for now... -->
    <!-- <widget-page
      v-if="clientHasIntroduce && userId"
      :url="`/team/${userId}/career_development_summary`"
      widgetId="EmployeeCareerDevelopmentHomepage"
    /> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import pluralize from "pluralize";
import { useShepherd } from "vue-shepherd";
import "shepherd.js/dist/css/shepherd.css";

import { appsObj } from "@cp/constants/apps";
import { onWindowReFocus, onCpEvent } from "@cp/mixins";
import { isEmpty } from "@cp/utils/objectUtils";

import { taskStatusForm } from "../store/modules/taskStatusForm";

const intouch = {
  ...appsObj.redstone,
  listItemProps: {
    class: "green lighten-5",
  },
  chipText: "New!",
  chipProps: {
    color: "success",
    offsetX: 30,
    offsetY: 40,
  },
};

const homepageApps = [appsObj.ingage, appsObj.inquire, intouch, appsObj.fiver];

export default {
  name: "Home",
  mixins: [
    onWindowReFocus(function() {
      this.fetch();
    }),
    onCpEvent(taskStatusForm.keys.modalClosedEvent, function({ success }) {
      if (success) this.fetch();
    }),
  ],
  data() {
    return {
      historicalPlans: false,
      colors: {
        pending: "#FEDC08",
        complete: "#008A09",
        overdue: "#E40000",
      },
    };
  },
  computed: {
    ...mapState("dashboard", [
      "myAssignments",
      "myAssignmentsParams",
      "myAssignmentsLoading",
      "tasksCount",
    ]),
    ...mapGetters("auth", ["isImpersonating"]),
    ...mapGetters("haystackUser", [
      "avatarUrl",
      "clientServices",
      "userServices",
      "identity",
      "isImpersonatingUser",
      "clientHasService",
    ]),
    ...mapGetters("dashboard", [
      "myManagers",
      "myReports",
      "myPeers",
      "myCareerDevelopment",
      "myTraining",
    ]),
    plans() {
      if (this.historicalPlans) return this.myCareerDevelopment;
      return this.myCareerDevelopment.filter(
        ({ status }) => status === "pending"
      );
    },
    showNextMonth: {
      get() {
        return this.myAssignmentsParams.before === "next_month";
      },
      set(value) {
        return this.updateMyAssignmentsParams({
          before: value ? "next_month" : "next_week",
        });
      },
    },
    userId() {
      // Don't show before identity is back
      if (!this.identity || !this.identity.id) return false;
      // Don't show when SBUser is impersonating, but not impersonating a user;
      if (this.isImpersonating && this.isImpersonatingUser) return false;
      return this.identity.id ? this.identity.id.split(",")[0] : false;
    },
    apps() {
      return homepageApps.filter(a => this.userServices.includes(a.serviceId));
    },
    clientHasIntroduce() {
      return this.clientServices.includes("introduce");
    },
  },
  methods: {
    isEmpty,
    pluralize,
    ...mapActions("dashboard", [
      "fetchMyAssignments",
      "fetchTasksCount",
      "updateMyAssignmentsParams",
    ]),
    ...mapActions("profile", {
      openProfileEditModal: "openItemFormModal",
    }),
    fetch() {
      this.fetchMyAssignments();
      this.fetchTasksCount();
    },
    startTour() {
      const tour = useShepherd({
        useModalOverlay: true,
        exitOnEsc: true,
      });

      tour.addStep({
        text: "Click here to switch between apps",
        attachTo: {
          element: "#Bento",
          on: "bottom",
        },
      });

      tour.start();

      window.addEventListener("mousedown", tour.next);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.profile-item {
  background: linear-gradient(90deg, #007dba 0%, #009a44 100%);
}

.avatarImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

:deep(.v-card__title) {
  display: flex;
  align-items: center;
  color: newColor(gray-6);
  .icon,
  .v-icon {
    color: newColor(gray-6);
    fill: newColor(gray-6);
  }
}

.cardLink:hover {
  text-decoration: none;
}

:deep(.v-input--switch) {
  .v-input__slot {
    margin-bottom: 3px;
  }
  .v-messages {
    display: none;
  }
}

.apps-list::v-deep {
  .v-list-item .v-list-item__icon {
    align-self: center;
    margin: 0 12px 0 0;
    height: 48px;
  }
  .v-list-item:hover {
    text-decoration: none;
  }
  .icon {
    fill: url(#greenBlueLTR);
  }
  .v-list-item__action {
    margin-right: -24px;
  }
}
</style>
