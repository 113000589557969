<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Reasons Residents Are Leaving
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'widget_docs',
            params: {
              category: 'regions',
              widget: 'resident_reasons_leaving_report',
              id: $router.currentRoute.params.id,
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <v-card>
      <v-card-text>
        <div class="mc-responses">
          <div
            v-if="!total || total <= 0"
            class="font-weight-bold text-center mt-3"
          >
            No Responses
          </div>

          <template v-else>
            <h5 class="mc-response-header">
              Total Responses: <span class="font-weight-bold">{{ total }}</span>
            </h5>
            <div
              class="mc-response-wrapper"
              v-for="(response, index) in responses"
              :key="index"
            >
              <h5 class="mc-response-text">
                {{  response.value }}<span class="font-weight-bold mr-2">&nbsp;-&nbsp;{{ response.label }}</span>
                 - {{ totalPercentage(response.value) }}%
              </h5>
              <div
                class="mc-response-bar"
                :style="
                  `width: ${percentage(
                    response.value
                  )}%; background-color: ${getColor(response)};`
                "
              ></div>
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>

    <card
      title="Other responses to: Why aren't you very likely to renew?"
      icon="chat-question-outline"
      class="mt-6"
    >
      <div v-for="(r, i) in data" :key="i">
        <v-alert
          v-if="r.other_text_response"
          icon="mdi-message-outline"
          color="blue-grey"
          text
          class="py-1"
        >
          <div class="commentQuestion text-caption d-flex align-center">
            <span v-if="r.location_name" class="d-flex align-center">
              <v-icon class="mr-1" small>mdi-home</v-icon> {{ r.location_name }}
              <span class="mx-3">|</span>
            </span>
            <v-icon class="mr-1" small>mdi-account</v-icon>
            {{ r.first_name }} {{ r.last_name }}
            <span class="mx-3">|</span>
            {{ toMDY(r.created_at) }}
          </div>
          {{ r.other_text_response }}
        </v-alert>
      </div>

      <v-pagination
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </card>
  </div>
</template>

<script>
import intersect from "vuetify/lib/directives/intersect";

import { get } from "@cp/utils/objectUtils";

import { widgetMixin, tableMixin } from "@/widgets/mixins";

export default {
  directives: { intersect },
  mixins: [widgetMixin, tableMixin],
  computed: {
    responses() {
      return get(
        this.meta,
        [
          "summary_object",
          "reason_breakdown",
          0,
          "response_summary",
          "multiple_select",
        ],
        []
      );
    },

    total() {
      let values = this.responses.map(x => x.value);
      return values.reduce((partialSum, val) => partialSum + val, 0);
    },

    max() {
      return this.responses[0].value;
    },
  },
  methods: {
    percentage(value) {
      return (value / this.max) * 100;
    },

    totalPercentage(value) {
      return Math.round((value / this.total) * 100);
    },

    getColor(response) {
      let percentage = this.percentage(response.value);
      let amount = 50 * (percentage / 100);
      let final = 240 - Math.round(amount);
      return `rgb(${final}, ${final}, ${final * 1.45})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-responses {
  text-align: left;
  margin-bottom: 12px;

  .mc-response-header {
    position: relative;
    left: 6px;
  }

  .mc-response-wrapper {
    position: relative;
    height: 28px;
    margin-top: 2px;
    margin-bottom: 2px;

    .mc-response-text {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding: 0 6px;
      height: 28px;
    }

    .mc-response-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

.text-h6 {
  background: rgba($gray-3, 0.25);
  margin-bottom: 14px;
  padding: 4px 16px;
}

.commentsInMonth {
  padding: 0 16px;
}

// ::v-deep .v-alert--text {
//   padding-top: 4px;
//   padding-bottom: 0;
// }

.commentQuestion {
  color: $gray-4;
  margin-bottom: 2px;
}

.commentDate {
  color: $gray-4;
  text-align: right;

  margin-top: 8px;
}
</style>
